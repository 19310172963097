import * as React from 'react'

import {
  formatDistanceToNow,
  getContentRoutePathname,
  i18n,
  Routes,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import {
  ArticlePreview,
  ArticlePreviewProps,
} from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import { mq, spacing, styled } from '@thg-commerce/gravity-theme'

interface FourUpPost {
  author: {
    name: string
    uri: string
    __typename: string
  }
  databaseId: number
  date: string
  titleDecoded: string
  rawExcerpt: string
  link: string
  postCategories: [
    {
      name: string
      link: string
      __typename: string
    },
    {
      name: string
      link: string
      __typename: string
    },
  ]
  featuredImage: {
    mediaItemUrl: string
    altText: string
    __typename: string
  }
  __typename: string
}

export interface OriginalPostDataProps {
  largePost: FourUpPost
  mediumPost: FourUpPost
  smallOnePost: FourUpPost
  smallTwoPost: FourUpPost
}

const StyledGrid = styled(Grid)`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0 ${spacing(4)};
  }
`

const LargeArticlePreview = styled(ArticlePreview)`
  flex-direction: column;
  margin-top: ${spacing(5)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row-reverse;
    align-items: center;
    margin: ${spacing(5)} 0 ${spacing(2)} 0;
  }
`

const FourUpArticle = (
  originalPostData,
  imageHeightArray?,
): ArticlePreviewProps => {
  const { contentRoutePrefix, contentSlug } = useSiteDefinition()

  const removeUnnecessaryStartOfURL = (
    originalURL: string,
    removeUntil: string | undefined,
  ) => {
    if (removeUntil) {
      return originalURL.substring(originalURL.indexOf(removeUntil))
    }
    return originalURL
  }

  const formattedArticleURL = removeUnnecessaryStartOfURL(
    originalPostData.link,
    contentSlug,
  )
  const formattedAuthorURL =
    originalPostData.author.uri.indexOf('/author') === 0
      ? originalPostData.author.uri.substring(8)
      : originalPostData.author.uri
  const formattedCategoryURL = removeUnnecessaryStartOfURL(
    originalPostData['postCategories'][0].link,
    contentSlug,
  )

  const postDate = new Date(originalPostData.date)
  const postDateStamp: string = formatDistanceToNow(postDate, {
    addSuffix: true,
  })

  return {
    title: originalPostData.titleDecoded,
    i18nAuthorText: i18n(
      'content.authorprefix.text',
      originalPostData.author.name,
    ),
    category: originalPostData['postCategories'][0].name,
    categoryUrl: getContentRoutePathname(
      contentRoutePrefix,
      formattedCategoryURL.replace(`${contentSlug}/`, ''),
    ),
    description: originalPostData.rawExcerpt,
    image: {
      src: [{ url: originalPostData.featuredImage.mediaItemUrl }],
      alt: originalPostData.featuredImage.altText,
      isAmp: false,
      lazy: false,
      height: imageHeightArray ? imageHeightArray : 'auto',
      width: '100%',
    },
    articleUrl: getContentRoutePathname(
      contentRoutePrefix,
      formattedArticleURL.replace(`${contentSlug}/`, ''),
    ),
    authorUrl: getContentRoutePathname(
      contentRoutePrefix,
      Routes.BlogAuthor.replace(':slug', formattedAuthorURL),
    ),
    articleAge: postDateStamp,
  }
}

export const FourUp = ({
  largePost,
  mediumPost,
  smallOnePost,
  smallTwoPost,
}: OriginalPostDataProps) => {
  return (
    <React.Fragment>
      <StyledGrid>
        <GridItem colSpan={[12, 12, 12, 12]}>
          <LargeArticlePreview
            {...FourUpArticle(largePost, [300, 370, 300, 400])}
            headingSize={'large'}
            direction={'column'}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6]}>
          <ArticlePreview
            {...FourUpArticle(mediumPost, [300, 370, 270, 350])}
            headingSize={'small'}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 3, 3]}>
          <ArticlePreview
            {...FourUpArticle(smallOnePost, [300, 370, 140, 170])}
            headingSize={'small'}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 3, 3]}>
          <ArticlePreview
            {...FourUpArticle(smallTwoPost, [300, 370, 140, 170])}
            headingSize={'small'}
          />
        </GridItem>
      </StyledGrid>
    </React.Fragment>
  )
}
